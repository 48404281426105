import { useNavigate } from '@remix-run/react';

import { MessageTemplateSettingsEditor } from '../components/Message/TemplateSettings/MessageTemplateSettingsEditor';

export function clientLoader() {
  return {};
}

export function Component() {
  const navigate = useNavigate();

  const back = () => {
    navigate('/admin/messages/campaigns');
  };

  return <MessageTemplateSettingsEditor onCancel={back} onSubmit={back} />;
}
